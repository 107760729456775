import { DestinationType, FinishSetupDestination } from '@/common/types/common.types'
import { UserDestination } from '@/proto/api_pb'
import { Box, Modal, styled } from '@mui/material'
import { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import AddDestinationView from './AddDestinationView'
import ConfirmDestinationView from './ConfirmDestinationView'

type ConfirmationModalProps = {
  open: boolean
  handleClose: () => void
  destinationType: DestinationType
  allUserDestinations: UserDestination.AsObject[]
  proposalDestination?: FinishSetupDestination
  methods: any
  onSubmit: (type: DestinationType) => Promise<boolean>
  onConfirm: () => Promise<string | boolean>
  reSubmit: () => Promise<boolean>
  reset: () => void
  updateSelectIds: (id: string) => void
  checkIsSameDestination: (allUserDestinations: UserDestination.AsObject[]) => any
  clearNotFinishedDestination: () => void
}

export default function ConfirmationModal({
  open,
  handleClose,
  destinationType,
  allUserDestinations,
  proposalDestination,
  methods,
  onSubmit,
  onConfirm,
  reSubmit,
  reset,
  updateSelectIds,
  checkIsSameDestination,
  clearNotFinishedDestination,
}: ConfirmationModalProps) {
  const [destinationId, setDestinationId] = useState('')

  const onCloseModal = () => {
    handleClose()
    reset()
    destinationId != '' && setDestinationId('')
  }

  const reSubmitDestination = async () => {
    await reSubmit()
  }

  const hadleSubmit = async (type: DestinationType) => {
    const isSameDestination = checkIsSameDestination(allUserDestinations)
    if (isSameDestination) {
      updateSelectIds(isSameDestination.id)
      onCloseModal()
      return
    }
    await onSubmit(type)
  }

  const confirmDestination = async () => {
    const response = await onConfirm()
    // add snack error bar here
    if (!response) return false
    updateSelectIds(response as string)
    clearNotFinishedDestination()
    onCloseModal()
  }

  let title =
    destinationType == 'EMAIL'
      ? 'Email destination'
      : destinationType == 'WEBHOOK'
      ? 'Webhook destination'
      : destinationType == 'TELEGRAM'
      ? 'Telegram Destination'
      : 'Discord Destination'

  let name =
    destinationType == 'EMAIL'
      ? 'email'
      : destinationType == 'WEBHOOK'
      ? 'webhook'
      : destinationType == 'TELEGRAM'
      ? 'telegram'
      : 'discord'

  let placeholder =
    destinationType == 'WEBHOOK'
      ? 'Webhook'
      : destinationType == 'EMAIL'
      ? 'Email'
      : destinationType == 'TELEGRAM'
      ? 'Telegram username'
      : 'Discord Webhook'

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalStyle>
        <FormProvider {...methods}>
          <>
            {destinationId != '' || proposalDestination?.confirmProposalId != '' ? (
              <ConfirmDestinationView
                title={title}
                destination={proposalDestination?.value}
                confirmDestination={confirmDestination}
                onCloseModal={onCloseModal}
                onSubmit={reSubmitDestination}
              />
            ) : (
              <AddDestinationView
                title={title}
                name={name}
                placeholder={placeholder}
                onSubmit={() => hadleSubmit(destinationType)}
                onCloseModal={onCloseModal}
                isValid={methods.formState.isValid}
              />
            )}
          </>
        </FormProvider>
      </ModalStyle>
    </Modal>
  )
}

const ModalStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  margin: 'auto',
  width: 500,
  backgroundColor: '#353535',
  padding: '0',
  borderRadius: '8px',
  [theme.breakpoints.down('md')]: {
    width: '350px',
  },
}))
