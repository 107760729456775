import { Box, IconButton, Typography } from '@mui/material'
import FormInput from '../FormInput/FormInput'
import { StyledButton } from '../StyledButton/StyledButton'
import CloseIcon from '@mui/icons-material/Close'

type AddDestinationViewProps = {
  title: string
  name: string
  placeholder: string
  onSubmit: () => void
  required?: boolean
  isValid: boolean
  onCloseModal: () => void
}

export default function AddDestinationView({
  title,
  name,
  placeholder,
  onSubmit,
  required,
  isValid,
  onCloseModal,
}: AddDestinationViewProps) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          borderBottom: '1px solid #FFFFFF3D',
          padding: '16px',
        }}
      >
        <Typography
          color="white"
          textAlign={'left'}
          sx={{
            width: '100%',
            fontSize: '22px',
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>
        <IconButton
          onClick={onCloseModal}
          sx={{
            border: '1px solid #FFFFFF3D',
            borderRadius: '8px',
            padding: '6px',
          }}
        >
          <CloseIcon
            sx={{
              color: 'white',
              fontSize: '20px',
            }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '20px',
        }}
      >
        <FormInput name={name} placeholder={placeholder} required={required} />
        <StyledButton disableElevation disabled={!isValid} onClick={onSubmit}>
          Create
        </StyledButton>
      </Box>
    </>
  )
}
