export const alertMessages = {
  alerts: {
    alertCreated: {
      message: 'Alert created successfully.',
      severity: 'success',
    },
    alertNotCreated: {
      message: 'Something went wrong creating the alert. Please try again later.',
      severity: 'error',
    },
    alertActive: {
      message: 'Alert activated successfully.',
      severity: 'success',
    },
    alertDeactivated: {
      message: 'Alert deactivated successfully.',
      severity: 'success',
    },
    alertDeleted: {
      message: 'Alert deleted successfully.',
      severity: 'success',
    },
    alertUpdated: {
      message: 'Alert updated successfully.',
      severity: 'success',
    },
  },
  destinations: {
    destinationCreated: {
      message: 'Destination created successfully.',
      severity: 'success',
    },
    destinationNotCreated: {
      message: 'Something went wrong creating the destination. Please try again later.',
      severity: 'error',
    },
    destinationActive: {
      message: 'Destination activated successfully.',
      severity: 'success',
    },
    destinationDeactivated: {
      message: 'Destination deactivated successfully.',
      severity: 'success',
    },
    destinationConfirmationCode: {
      message: 'Confirmation code sent successfully.',
      severity: 'info',
    },
    destinationNewConfirmationCode: {
      message: 'New confirmation code sent successfully.',
      severity: 'info',
    },
    isSameDestination: {
      message: 'This destination already exists, we will use it.',
      severity: 'warning',
    },
    invalidTelegramUsername: {
      message: 'Invalid Telegram username.',
      severity: 'error',
    },
    destinationDeleted: {
      message: 'Destination deleted successfully.',
      severity: 'success',
    },
  },
  generalError: {
    message: 'Something went wrong. Please try again later.',
    severity: 'error',
  },
}
