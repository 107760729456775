import { ChainType, DestinationType } from '@/common/types/common.types'
import { SmartContractEvent } from '@/modules/createAlert/types/CreateAlertTypes'
import {
  Alert,
  AlertCosmosMonitorFunds,
  AlertCosmosSendFunds,
  AlertCosmosSmartContractEvents,
  AlertCosmosTxOutcome,
  AlertEthMonitorFunds,
  AlertEthSmartContractEvents,
  AlertEthTxOutcome,
  ConfirmDestinationRegistrationRequest,
  ConfirmDestinationRegistrationResponse,
  CosmosAlert,
  CosmosEvmAlert,
  CreateAlertRequest,
  CreateAlertResponse,
  DeleteAlertRequest,
  DeleteAlertResponse,
  DeleteUserDestinationRequest,
  DeleteUserDestinationResponse,
  Destination,
  EthAlert,
  GetAlertsRequest,
  GetAlertsResponse,
  GetChainsRequest,
  GetChainsResponse,
  GetNotificationsRequest,
  GetNotificationsResponse,
  GetUserDestinationsRequest,
  GetUserDestinationsResponse,
  InitiateDestinationRegistrationRequest,
  InitiateDestinationRegistrationResponse,
  SubmitCognitoCodeGrantRequest,
  SubmitCognitoCodeGrantResponse,
  UpdateAlertRequest,
  UserAlert,
} from '@/proto/api_pb'
import { AlertTypeEnum, EthAlertTypeEnum, EvmAlertTypeEnum } from '../common/enums/AlertTypeEnum'
import { GatewayClient, GatewayPublicClient } from '../proto/ApiServiceClientPb'

type AlertProps = {
  alertId?: string
  alertType: EvmAlertTypeEnum | AlertTypeEnum | EthAlertTypeEnum
  setTo: string
  setFrom: string
  setAddress: string
  destinationIds: string[]
  chainId: string
  smartContractEvents: SmartContractEvent[]
  outcome: AlertEthTxOutcome.EthTxOutcome | AlertCosmosTxOutcome.CosmosTxOutcome
  chainType: ChainType
  contractAbi: string
  scEventName: string
  alertName: string
  alertStatus?: UserAlert.Status
  userId?: string
  userToken?: string
}

type ErrorType = {
  status: string
  data: any
}

type SuccessType = {
  status: string
  data: any
}

const apiService = new GatewayClient(process.env.API_URL as string)
const publicApiService = new GatewayPublicClient(process.env.API_URL as string)

class UserService {
  // --------------------------------- ALERT ---------------------------------
  // --------------------------------- ALERT ---------------------------------
  async createAlert({
    alertType,
    setTo,
    setFrom,
    setAddress,
    destinationIds,
    chainId,
    chainType,
    scEventName,
    contractAbi,
    smartContractEvents,
    outcome,
    alertName,
    userId,
    userToken,
  }: AlertProps): Promise<SuccessType | ErrorType> {
    if (userId === undefined || userId === '' || userId === null) {
      return { status: 'FAIL', data: 'User has no ID' }
    }

    try {
      const createAlertRequest: CreateAlertRequest = new CreateAlertRequest()
      createAlertRequest.setUserId(userId)
      createAlertRequest.setName(alertName)

      destinationIds.forEach(destinationId => {
        createAlertRequest.addDestinationIds(destinationId)
      })

      createAlertRequest.setChainId(chainId)

      const alert = buildAlert(
        chainType,
        alertType,
        setAddress,
        setTo,
        setFrom,
        outcome,
        contractAbi,
        scEventName,
        smartContractEvents
      )

      createAlertRequest.setAlert(alert)

      const response: CreateAlertResponse = await apiService.createAlert(createAlertRequest, {
        Authorization: 'Bearer ' + (await userToken),
        'Access-Control-Allow-Origin': process.env.DOMAIN_URL as string,
        'Access-Control-Allow-Credentials': 'true',
      })

      return { status: 'SUCCESS', data: response.toObject() }
    } catch (error) {
      return { status: 'FAIL', data: error }
    }
  }

  async updateAlert(alertProps: AlertProps): Promise<SuccessType | ErrorType> {
    try {
      const updateAlert: UpdateAlertRequest = new UpdateAlertRequest()
      const userAlert: UserAlert = new UserAlert()

      if (alertProps.alertId) {
        userAlert.setId(alertProps.alertId)
        userAlert.setName(alertProps.alertName)
        userAlert.setDestinationIdsList(alertProps.destinationIds)
        alertProps.alertStatus && userAlert.setStatus(alertProps.alertStatus)

        userAlert.setChainId(alertProps.chainId)
        const alert: Alert = buildAlert(
          alertProps.chainType,
          alertProps.alertType,
          alertProps.setAddress,
          alertProps.setTo,
          alertProps.setFrom,
          alertProps.outcome,
          alertProps.contractAbi,
          alertProps.scEventName,
          alertProps.smartContractEvents
        )
        userAlert.setAlert(alert)

        updateAlert.setAlert(userAlert)

        const response = await apiService.updateAlert(updateAlert, {
          Authorization: 'Bearer ' + alertProps.userToken,
          'Access-Control-Allow-Origin': process.env.DOMAIN_URL as string,
          'Access-Control-Allow-Credentials': 'true',
        })

        return { status: 'SUCCESS', data: response.toObject() }
      }
      return { status: 'FAIL', data: 'No Alert ID' }
    } catch (error) {
      return { status: 'FAIL', data: error }
    }
  }

  async setAlertStatus(
    alertId: string,
    status: UserAlert.Status,
    destinations: string[],
    alertName: string,
    userToken: string
  ): Promise<SuccessType | ErrorType> {
    try {
      const updateAlert: UpdateAlertRequest = new UpdateAlertRequest()
      const userAlert: UserAlert = new UserAlert()

      userAlert.setId(alertId)
      userAlert.setName(alertName)
      userAlert.setDestinationIdsList(destinations)

      userAlert.setStatus(status)
      updateAlert.setAlert(userAlert)

      const response = await apiService.updateAlert(updateAlert, {
        Authorization: 'Bearer ' + userToken,
        'Access-Control-Allow-Origin': process.env.DOMAIN_URL as string,
        'Access-Control-Allow-Credentials': 'true',
      })

      return { status: 'SUCCESS', data: response.toObject() }
    } catch (error) {
      return { status: 'FAIL', data: error }
    }
  }

  async getAlertsByUser(
    userId: string,
    userToken: string,
    page?: number
  ): Promise<SuccessType | ErrorType> {
    if (userId === '' || userId === null || userId === undefined) {
      return { status: 'FAIL', data: 'User has no ID' }
    }

    try {
      const getAlertsByUserRequest: GetAlertsRequest = new GetAlertsRequest()
      getAlertsByUserRequest.setUserId(userId)
      page && getAlertsByUserRequest.setPage(page)

      const response: GetAlertsResponse = await apiService.getAlerts(getAlertsByUserRequest, {
        Authorization: 'Bearer ' + userToken,
        'Access-Control-Allow-Origin': process.env.DOMAIN_URL as string,
        'Access-Control-Allow-Credentials': 'true',
      })

      return { status: 'SUCCESS', data: response.toObject() }
    } catch (e) {
      return { status: 'FAIL', data: e }
    }
  }

  async deleteAlert(alertId: string, userToken: string): Promise<SuccessType | ErrorType> {
    try {
      const deleteAlertRequest: DeleteAlertRequest = new DeleteAlertRequest()
      deleteAlertRequest.setAlertId(alertId)

      const response: DeleteAlertResponse = await apiService.deleteAlert(deleteAlertRequest, {
        Authorization: 'Bearer ' + userToken,
        'Access-Control-Allow-Origin': process.env.DOMAIN_URL as string,
        'Access-Control-Allow-Credentials': 'true',
      })

      return { status: 'SUCCESS', data: response.toObject() }
    } catch (e) {
      return { status: 'FAIL', data: e }
    }
  }

  // --------------------------------- DESTINATION ---------------------------------
  // --------------------------------- DESTINATION ---------------------------------
  async createDestination(
    destinationType: DestinationType,
    value: string,
    userId: string,
    userToken: string
  ): Promise<SuccessType | ErrorType> {
    if (userId === '' || userId === null || userId === undefined) {
      return { status: 'FAIL', data: 'User has no ID' }
    }

    try {
      const destination = new Destination()
      destinationType === 'EMAIL'
        ? destination.setEmail(value)
        : destinationType === 'WEBHOOK'
        ? destination.setWebhook(value)
        : destinationType === 'TELEGRAM'
        ? destination.setTelegram(value)
        : destination.setDiscordWebhook(value)

      const destinationRequest = new InitiateDestinationRegistrationRequest()
      destinationRequest.setUserId(userId)
      destinationRequest.setDestination(destination)

      const response: InitiateDestinationRegistrationResponse =
        await apiService.initiateDestinationRegistration(destinationRequest, {
          Authorization: 'Bearer ' + userToken,
          'Access-Control-Allow-Origin': process.env.DOMAIN_URL as string,
          'Access-Control-Allow-Credentials': 'true',
        })

      return { status: 'SUCCESS', data: response.toObject().destinationRegistrationProposalId }
    } catch (error) {
      return { status: 'FAIL', data: error }
    }
  }

  async confirmDestination(
    destinationId: string,
    code: string,
    userId: string,
    userToken: string
  ): Promise<SuccessType | ErrorType> {
    if (userId === '' || userId === null || userId === undefined) {
      return { status: 'FAIL', data: 'User has no ID' }
    }

    try {
      const confirmDestinationRequest = new ConfirmDestinationRegistrationRequest()
      confirmDestinationRequest.setConfirmationCode(code)
      confirmDestinationRequest.setDestinationRegistrationProposalId(destinationId)

      const response: ConfirmDestinationRegistrationResponse =
        await apiService.confirmDestinationRegistration(confirmDestinationRequest, {
          Authorization: 'Bearer ' + userToken,
          'Access-Control-Allow-Origin': process.env.DOMAIN_URL as string,
          'Access-Control-Allow-Credentials': 'true',
        })

      return { status: 'SUCCESS', data: response.toObject() }
    } catch (error) {
      return { status: 'FAIL', data: error }
    }
  }

  async getUserDestinations(userId: string, userToken: string): Promise<SuccessType | ErrorType> {
    if (userId === '' || userId === null || userId === undefined) {
      return { status: 'FAIL', data: 'User has no ID' }
    }

    try {
      const getUserDestination = new GetUserDestinationsRequest()
      getUserDestination.setUserId(userId)

      const response: GetUserDestinationsResponse = await apiService.getUserDestinations(
        getUserDestination,
        {
          Authorization: 'Bearer ' + userToken,
          'Access-Control-Allow-Origin': process.env.DOMAIN_URL as string,
          'Access-Control-Allow-Credentials': 'true',
        }
      )

      return { status: 'SUCCESS', data: response.toObject() }
    } catch (error) {
      return { status: 'FAIL', data: error }
    }
  }

  async deleteDestination(
    destinationId: string,
    userId: string,
    userToken: string
  ): Promise<SuccessType | ErrorType> {
    if (userId === '' || userId === null || userId === undefined) {
      return { status: 'FAIL', data: 'User has no ID' }
    }

    try {
      const deleteUserDestination: DeleteUserDestinationRequest = new DeleteUserDestinationRequest()
      deleteUserDestination.setId(destinationId)

      const response: DeleteUserDestinationResponse = await apiService.deleteUserDestination(
        deleteUserDestination,
        {
          Authorization: 'Bearer ' + userToken,
          'Access-Control-Allow-Origin': process.env.DOMAIN_URL as string,
          'Access-Control-Allow-Credentials': 'true',
        }
      )

      return { status: 'SUCCESS', data: response.toObject() }
    } catch (error) {
      return { status: 'FAIL', data: error }
    }
  }

  // --------------------------------- NOTIFICATION ---------------------------------
  // --------------------------------- NOTIFICATION ---------------------------------
  async getNotificationByAlert(
    alertId: string,
    userToken: string,
    page?: number
  ): Promise<SuccessType | ErrorType> {
    console.log('pageeee', page)
    try {
      const getNotificationByAlertRequest = new GetNotificationsRequest()
      getNotificationByAlertRequest.setAlertId(alertId)
      page && getNotificationByAlertRequest.setPage(page)

      const response: GetNotificationsResponse = await apiService.getNotifications(
        getNotificationByAlertRequest,
        {
          Authorization: 'Bearer ' + userToken,
          'Access-Control-Allow-Origin': process.env.DOMAIN_URL as string,
          'Access-Control-Allow-Credentials': 'true',
        }
      )

      console.log('response', response.toObject())
      return { status: 'SUCCESS', data: response.toObject() }
    } catch (error) {
      return { status: 'FAIL', data: error }
    }
  }

  // --------------------------------- CHAINS ---------------------------------
  // --------------------------------- CHAINS ---------------------------------
  async getChains(userId: string, userToken: string): Promise<SuccessType | ErrorType> {
    try {
      const getChainRequest = new GetChainsRequest()
      const response: GetChainsResponse = await apiService.getChains(getChainRequest, {
        Authorization: 'Bearer ' + userToken,
        'Access-Control-Allow-Origin': process.env.DOMAIN_URL as string,
        'Access-Control-Allow-Credentials': 'true',
      })
      return { status: 'SUCCESS', data: response.toObject() }
    } catch (error) {
      return { status: 'FAIL', data: error }
    }
  }

  // --------------------------------- TOKEN ---------------------------------
  // --------------------------------- TOKEN ---------------------------------
  async getUserToken(cognitoCode: string): Promise<SuccessType | ErrorType> {
    const submitCognitoCode: SubmitCognitoCodeGrantRequest = new SubmitCognitoCodeGrantRequest()

    submitCognitoCode.setCode(cognitoCode)

    try {
      const response: SubmitCognitoCodeGrantResponse =
        await publicApiService.submitCognitoCodeGrant(submitCognitoCode, {})

      return { status: 'SUCCESS', data: response.toObject() }
    } catch (e) {
      console.log('error ----', e)
      return { status: 'FAIL', data: e }
    }
  }
}

export const userService = new UserService()

function buildAlert(
  chainType: string,
  alertType: EvmAlertTypeEnum | AlertTypeEnum | EthAlertTypeEnum,
  setAddress: string,
  setTo: string,
  setFrom: string,
  outcome: AlertEthTxOutcome.EthTxOutcome | AlertCosmosTxOutcome.CosmosTxOutcome,
  contractAbi: string,
  scEventName: string,
  smartContractEvents: SmartContractEvent[]
): Alert {
  const alert = new Alert()
  const cosmosAlert = new CosmosAlert()

  if (chainType === 'COSMOS_EVM') {
    const evmAlert = new CosmosEvmAlert()
    switch (alertType) {
      case EvmAlertTypeEnum.EVM_MONITOR_FUNDS:
        const ethMsgAlertMonitorFunds = new AlertEthMonitorFunds()
        ethMsgAlertMonitorFunds.setAddress(setAddress)
        evmAlert.setAlertEthMonitorFunds(ethMsgAlertMonitorFunds)
        alert.setCosmosEvmAlert(evmAlert)
        break
      case EvmAlertTypeEnum.EVM_TX_OUTCOME:
        const txAlertOutcomeEvm = new AlertEthTxOutcome()
        txAlertOutcomeEvm.setSigner(setAddress)
        txAlertOutcomeEvm.setOutcome(outcome as AlertEthTxOutcome.EthTxOutcome)

        evmAlert.setAlertEthTxOutcome(txAlertOutcomeEvm)
        alert.setCosmosEvmAlert(evmAlert)
        break
      case EvmAlertTypeEnum.EVM_SMART_CONTRACT_EVENTS:
        const msgAlertSmartContractEvents = new AlertEthSmartContractEvents()
        msgAlertSmartContractEvents.setContractAddr(setAddress)
        msgAlertSmartContractEvents.setContractAbi(contractAbi)
        msgAlertSmartContractEvents.setEventName(scEventName)

        smartContractEvents.map((event: SmartContractEvent) => {
          msgAlertSmartContractEvents.getEventAttributesMap().set(event.eventName, event.eventValue)
        })

        evmAlert.setAlertEthSmartContractEvents(msgAlertSmartContractEvents)
        alert.setCosmosEvmAlert(evmAlert)
        break
      case EvmAlertTypeEnum.COSMOS_MONITOR_FUNDS:
        const msgAlertMonitorFunds = new AlertCosmosMonitorFunds()
        msgAlertMonitorFunds.setAddress(setAddress)

        cosmosAlert.setAlertCosmosMonitorFunds(msgAlertMonitorFunds)
        alert.setCosmosAlert(cosmosAlert)
        break
      case EvmAlertTypeEnum.COSMOS_TX_OUTCOME:
        const txAlertOutcomeCosmos = new AlertCosmosTxOutcome()

        txAlertOutcomeCosmos.setSigner(setAddress)
        txAlertOutcomeCosmos.setOutcome(outcome as AlertCosmosTxOutcome.CosmosTxOutcome)

        cosmosAlert.setAlertCosmosTxOutcome(txAlertOutcomeCosmos)
        alert.setCosmosAlert(cosmosAlert)
        break
    }
  } else if (chainType === 'COSMOS') {
    switch (alertType) {
      case AlertTypeEnum.SEND_FUNDS:
        const msgAlertSendFunds = new AlertCosmosSendFunds()
        msgAlertSendFunds.setTo(setTo)
        msgAlertSendFunds.setFrom(setFrom)

        cosmosAlert.setAlertCosmosSendFunds(msgAlertSendFunds)
        alert.setCosmosAlert(cosmosAlert)
        break
      case AlertTypeEnum.MONITOR_FUNDS:
        const msgAlertMonitorFunds = new AlertCosmosMonitorFunds()
        msgAlertMonitorFunds.setAddress(setAddress)

        cosmosAlert.setAlertCosmosMonitorFunds(msgAlertMonitorFunds)
        alert.setCosmosAlert(cosmosAlert)
        break
      case AlertTypeEnum.SMART_CONTRACT_EVENTS:
        const msgAlertSmartContractCosmos = new AlertCosmosSmartContractEvents()
        msgAlertSmartContractCosmos.setAddress(setAddress)
        msgAlertSmartContractCosmos.setEventName(scEventName)

        smartContractEvents.map((event: SmartContractEvent) => {
          msgAlertSmartContractCosmos.getEventAttributesMap().set(event.eventName, event.eventValue)
        })

        cosmosAlert.setAlertCosmosSmartContractEvents(msgAlertSmartContractCosmos)
        alert.setCosmosAlert(cosmosAlert)
        break
      case AlertTypeEnum.TX_OUTCOME:
        const txAlertOutcomeCosmos = new AlertCosmosTxOutcome()

        txAlertOutcomeCosmos.setSigner(setAddress)
        txAlertOutcomeCosmos.setOutcome(outcome as AlertCosmosTxOutcome.CosmosTxOutcome)

        cosmosAlert.setAlertCosmosTxOutcome(txAlertOutcomeCosmos)
        alert.setCosmosAlert(cosmosAlert)
        break
    }
  } else {
    const ethAlert = new EthAlert()
    switch (alertType) {
      case EthAlertTypeEnum.ETH_MONITOR_FUNDS:
        const msgAlertSendFunds = new AlertEthMonitorFunds()

        msgAlertSendFunds.setAddress(setAddress)

        ethAlert.setAlertEthMonitorFunds(msgAlertSendFunds)
        alert.setEthAlert(ethAlert)
        break
      case EthAlertTypeEnum.ETH_SMART_CONTRACT_EVENTS:
        const msgAlertSmartContractEvents = new AlertEthSmartContractEvents()
        msgAlertSmartContractEvents.setContractAddr(setAddress)
        msgAlertSmartContractEvents.setContractAbi(contractAbi)
        msgAlertSmartContractEvents.setEventName(scEventName)

        smartContractEvents.map((event: SmartContractEvent) => {
          msgAlertSmartContractEvents.getEventAttributesMap().set(event.eventName, event.eventValue)
        })

        ethAlert.setAlertEthSmartContractEvents(msgAlertSmartContractEvents)
        alert.setEthAlert(ethAlert)
        break
      case EthAlertTypeEnum.ETH_TX_OUTCOME:
        const txAlertOutcomeEvm = new AlertEthTxOutcome()
        txAlertOutcomeEvm.setSigner(setAddress)
        txAlertOutcomeEvm.setOutcome(outcome as AlertEthTxOutcome.EthTxOutcome)

        ethAlert.setAlertEthTxOutcome(txAlertOutcomeEvm)
        alert.setEthAlert(ethAlert)
        break
    }
  }

  return alert
}
